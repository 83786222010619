@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Bitter:wght@300&display=swap');

@layer base {
  h1 {
    @apply text-8xl;
  }
  a {
    @apply underline;
  }
}

body {
  @apply bg-gray-800;
  @apply text-gray-50;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
